import React from 'react';
import { Button } from 'antd';
import './MarketPlatform.scss';

import userTabLogo from '@/assets/images/userTab.png';
import userGroupLogo from '@/assets/images/userGroup.png';
import userCopyLogo from '@/assets/images/userCopy.png';
import marketAutoLogo from '@/assets/images/marketAuto.png';
import userTravelLogo from '@/assets/images/userTravel.png';
import effectLogo from '@/assets/images/effect.png';
import wechatLogo from '@/assets/images/wechat.png';
import messageLogo from '@/assets/images/页面1_@2x-3.png';
import emailLogo from '@/assets/images/微信小商店_small.png';
import soldDateLogo from '@/assets/images/soldDate.png';
import formLogo from '@/assets/images/form.png';
import materialLogo from '@/assets/images/material.png';

function MarketPlatform(props) {
  // const MarketArrs = ["客户管理", "客户旅程", "全渠道触达", "营销助手"];
  const MarketArrs = {
    客户管理: [
      {
        icon: userTabLogo,
        title: '用户标签',
        content: '系统提供自动标签和手动标签的能力，用户特征一目了然',
      },
      {
        icon: userGroupLogo,
        title: '用户分组',
        content: '支持对不同类型的用户进行分组管理，便于针对性运营和管理',
      },
      {
        icon: userCopyLogo,
        title: '联系人库',
        content: '支持大容量、多信息、多维度的联系人信息储存，一键管理您的客户',
      },
    ],
    客户旅程: [
      {
        icon: marketAutoLogo,
        title: '营销自动化',
        content:
          '支持多种营销类型：主动式营销、触发式营销、周期性营销、纪念日营销，让营销更高效精准',
      },
      {
        icon: userTravelLogo,
        title: '客户旅程管理',
        content:
          '通过营销自动化引擎，在一系列主动触达和创意内容在时间、顺序及触点上有机组合，完成一次有创意、有温度、有效的高效营销',
      },
      {
        icon: effectLogo,
        title: '高效执行',
        content:
          '提供丰富的执行和控制模块，高效实现各类客户旅程的设计和执行，简单省心',
      },
    ],
  };
  const wayArrs = {
    全渠道触达: [
      {
        icon: messageLogo,
        title: '企业微信',
        content: '支持单聊，群聊，微信朋友圈等微信功能管理',
      },
      {
        icon: wechatLogo,
        title: '微信公众号',
        content: '支持微信公众号粉丝数据查看与分析',
      },
      {
        icon: emailLogo,
        title: '微信小商店',
        content: '支持小商店，营销活动管理',
      },
    ],
    营销助手: [
      {
        icon: soldDateLogo,
        title: '营销日历',
        content:
          '提供用户会员积分的能力，根据用户行为累计积分，轻松提高用户忠诚度',
      },
      {
        icon: formLogo,
        title: '表单',
        content: '灵活的表单创建与管理功能功能，表单一键应用，数据只能收集',
      },
      {
        icon: materialLogo,
        title: '素材',
        content:
          '可根据不同的会员定制不同的权益，发送优惠券、打折等，玩法多样促进成交',
      },
    ],
  };

  const { openModal } = props;
  const handleJumpToForm = (anchorName) => {
    // if (anchorName) {
    //   let anchorElement = document.getElementById(anchorName);
    //   if (anchorElement) {
    //     anchorElement.scrollIntoView();
    //   }
    // }
    openModal();
  };
  return (
    <div className="platformLayout">
      <div className="platformText">
        <span className="platformTitle">营销中台，一站式管理您的私域生态</span>
        <span className="platformDetail">
          一个平台管理您的所有私域流量，省时省心
        </span>
      </div>
      <div className="plarformCard">
        {Object.keys(MarketArrs).map((group, index) => (
          <div
            className="cardContainer"
            style={{ marginRight: index === 1 ? 0 : 40 }}
          >
            <div className="cardName">{group}</div>
            <div className="cardContent">
              {MarketArrs[group].map((item) => (
                <div className="cardtitle">
                  <img src={item.icon} alt="" className="cardLogo" />
                  <span className="cardMainTitle">{item.title}</span>
                  <span className="cardText">{item.content}</span>
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
      <div className="plarformCard">
        {Object.keys(wayArrs).map((group, index) => (
          <div
            className="cardContainer"
            style={{ marginRight: index === 1 ? 0 : 40 }}
          >
            <div className="cardName">{group}</div>
            <div className="cardContent">
              {wayArrs[group].map((item) => (
                <div className="cardtitle">
                  <img src={item.icon} alt="" className="cardLogo" />
                  <span className="cardMainTitle">{item.title}</span>
                  <span className="cardText">{item.content}</span>
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
      <div className="platformButton">
        <Button
          type="primary"
          className="platformBtn"
          onClick={() => handleJumpToForm('targetForm')}
        >
          立即体验
        </Button>
      </div>
    </div>
  );
}

export default MarketPlatform;
