import React from 'react';
import { Button } from 'antd';
import './UserTravel.scss';
import UserImg from '@/assets/images/用户旅程@1x.png';
import UserTravelLogo from '@/assets/images/用户旅程_@3x-2.png';
import UserDataLogo from '@/assets/images/user_data.png';
import UserManageLogo from '@/assets/images/user_manage1.png';

function UserTravel(props) {
  const { openModal } = props;
  const handleJumpToForm = (anchorName) => {
    // if (anchorName) {
    //   let anchorElement = document.getElementById(anchorName);
    //   if (anchorElement) {
    //     anchorElement.scrollIntoView();
    //   }
    // }
    openModal();
  };
  const userTravelArrs = [
    {
      icon: UserTravelLogo,
      title: '用户旅程',
      content:
        '可视化用户旅程编辑，追踪客户行为轨迹，建立完整用户画像，洞察客户真实需求，智能化推荐引擎',
    },
    {
      icon: UserManageLogo,
      title: '精细化管理用户',
      content:
        '客户细分精准运营，智能化标签和人群分层，形成有效客户洞察，高效跟进客户，帮助企业提高生产销售效率',
    },
    {
      icon: UserDataLogo,
      title: '自动化管理数据',
      content:
        '全渠道客户数据自动汇总，建立客户数据平台，营销自动化运营，减少重复性工作，降低营销成本',
    },
  ];
  return (
    <div className="userLayout">
      <div className="userText">
        <span className="userTitle">
          用户旅程，精细化自动化管理用户生命周期
        </span>
        <span className="userDetail">
          根据客户不同阶段的不同特性，自动化提供针对性营销，高效促转化
        </span>
      </div>
      <div className="userContent">
        <div className="userTab">
          {userTravelArrs.map((item) => (
            <div className="tabTitle">
              <div className="tabImgBackground">
                {' '}
                <img src={item.icon} alt="" className="tabLogo" />
              </div>

              <div>
                <span className="tabMainTitle">{item.title}</span>
                <span className="tabContent">{item.content}</span>
              </div>
            </div>
          ))}
        </div>
        <div className="userImg">
          <img alt="" src={UserImg} className="userImg" />
        </div>
      </div>

      <div className="userButton">
        <Button
          type="primary"
          className="userBtn"
          onClick={() => handleJumpToForm('targetForm')}
        >
          立即体验
        </Button>
      </div>
    </div>
  );
}

export default UserTravel;
