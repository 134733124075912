import React from 'react';
import { Modal, Button } from 'antd';
import './styles.scss';
import FormHtmlContent from '@/UserExample/FormHtml';
import MaskGroupImg from '@/assets/images/MaskGroup.png';

const RegisterForm = (props) => {
  const { visible, closeModal } = props;
  console.log(visible);
  return (
    <>
      <Modal
        width={900}
        visible={visible}
        footer={
          [] // 设置footer为空，去掉 取消 确定默认按钮
        }
        onCancel={closeModal}
      >
        <div className="registerForm" id="targetForm">
          <span className="contactTitle">联系我们</span>
          <span className="contactContent">
            留下你的联系方式，团队将尽快与你取得联系
          </span>
          <div className="contactForm">
            <div className="FormImg">
              <img src={MaskGroupImg} alt=""></img>
            </div>
            <div dangerouslySetInnerHTML={{ __html: FormHtmlContent }}></div>
          </div>
        </div>
      </Modal>
    </>
  );
};
export default RegisterForm;
