import React from 'react';
import { Button } from 'antd';
import './FissionMarket.scss';
import FissionImg from '@/assets/images/资源13@1x.png';
import soldLogo from '@/assets/images/sold.png';
import bargainLogo from '@/assets/images/bargain.png';
import groupBuyLogo from '@/assets/images/groupBuy.png';
import recommendLogo from '@/assets/images/recommend.png';
function FissionMarket(props) {
  const fissionArr = [
    {
      icon: bargainLogo,
      title: '助力砍价',
      content: '支持分享转发链接给好友，点击注册即可完成助力砍价',
    },
    {
      icon: recommendLogo,
      title: '推荐邀请',
      content: '将活动链接分享给朋友参与活动，朋友注册即可获得优惠',
    },
    {
      icon: soldLogo,
      title: '营销活动',
      content: '支持红包、优惠券、幸运大转盘等奖励方法',
    },
  ];

  const { openModal } = props;
  const handleJumpToForm = (anchorName) => {
    // if (anchorName) {
    //   let anchorElement = document.getElementById(anchorName);
    //   if (anchorElement) {
    //     anchorElement.scrollIntoView();
    //   }
    // }
    openModal();
  };

  return (
    <div className="fissionLayout">
      <div className="fissionText">
        <span className="fissionTitle">裂变营销，高效让您的用户快速增长</span>
        <span className="fissionDetail">
          我们提供高效低价的营销落地能力，赋能您的业务裂变增长
        </span>
      </div>
      <div className="fissionContent">
        <div className="fissionTab">
          {fissionArr.map((item) => (
            <div className="tabTitle">
              <div className="fissionImgBackground">
                <img src={item.icon} alt="" className="tabLogo" />
              </div>

              <div>
                <span className="tabMainTitle">{item.title}</span>
                <span className="tabContent">{item.content}</span>
              </div>
            </div>
          ))}
        </div>
        <div className="fissionImg">
          <img alt="" src={FissionImg} />
        </div>
      </div>

      <div className="fissionButton">
        <Button
          type="primary"
          className="fissionBtn"
          onClick={() => handleJumpToForm('targetForm')}
        >
          立即体验
        </Button>
      </div>
    </div>
  );
}

export default FissionMarket;
