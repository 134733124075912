const FormHtmlContent = [
  '<div id="mauticform_wrapper_zengzhangmofangguanwang" class="mauticform_wrapper">',
  '  <form',
  '    autocomplete="false"',
  '    role="form"',
  '    method="post"',
  '    action="http://118.25.8.86/form/submit?formId=1"',
  '    id="mauticform_zengzhangmofangguanwang"',
  '    data-mautic-form="zengzhangmofangguanwang"',
  '    enctype="multipart/form-data"',
  '  >',
  '    <div',
  '      class="mauticform-error"',
  '      id="mauticform_zengzhangmofangguanwang_error"',
  '    ></div>',
  '    <div',
  '      class="mauticform-message"',
  '      id="mauticform_zengzhangmofangguanwang_message"',
  '    ></div>',
  '    <div class="mauticform-innerform">',
  '      <div',
  '        class="mauticform-page-wrapper mauticform-page-1"',
  '        data-mautic-form-page="1"',
  '      >',
  '        <div',
  '          id="mauticform_zengzhangmofangguanwang_xing_ming1"',
  '          data-validate="xing_ming1"',
  '          data-validation-type="text"',
  '          class="mauticform-row mauticform-text mauticform-field-1 mauticform-required"',
  '        >',
  '          <input',
  '            id="mauticform_input_zengzhangmofangguanwang_xing_ming1"',
  '            name="mauticform[xing_ming1]"',
  '            value=""',
  '            class="mauticform-input"',
  '            type="text"',
  '            placeholder="姓名"',
  '          />',
  '          <span class="mauticform-errormsg" style="display: none"',
  '            >本项为必填。</span',
  '          >',
  '        </div>',
  '        <div',
  '          id="mauticform_zengzhangmofangguanwang_shou_ji_hao"',
  '          data-validate="shou_ji_hao"',
  '          data-validation-type="tel"',
  '          class="mauticform-row mauticform-tel mauticform-field-2 mauticform-required"',
  '        >',
  '          <input',
  '            id="mauticform_input_zengzhangmofangguanwang_shou_ji_hao"',
  '            name="mauticform[shou_ji_hao]"',
  '            value=""',
  '            class="mauticform-input"',
  '            type="tel"',
  '            placeholder="手机号"',
  '          />',
  '          <span class="mauticform-errormsg" style="display: none"',
  '            >本项为必填。</span',
  '          >',
  '        </div>',
  '        <div',
  '          id="mauticform_zengzhangmofangguanwang_gong_si_ming_cheng"',
  '          class="mauticform-row mauticform-text mauticform-field-3 mauticform-required"',
  '        >',
  '          <input',
  '            id="mauticform_input_zengzhangmofangguanwang_gong_si_ming_cheng"',
  '            name="mauticform[gong_si_ming_cheng]"',
  '            value=""',
  '            class="mauticform-input"',
  '            type="text"',
  '            placeholder="公司名称"',
  '          />',
  '          <span class="mauticform-errormsg" style="display: none"></span>',
  '        </div>',
  '        <div',
  '          id="mauticform_zengzhangmofangguanwang_gong_si_gui_mo"',
  '          class="mauticform-row mauticform-select mauticform-field-4 mauticform-required"',
  '        >',
  '          <select',
  '            id="mauticform_input_zengzhangmofangguanwang_gong_si_gui_mo"',
  '            name="mauticform[gong_si_gui_mo]"',
  '            value=""',
  '            class="mauticform-selectbox"',
  '            placeholder="公司规模"',
  '          >',
  '<option value="1" selected disabled style="display: none; color: gray;">公司规模</option>',
  '            <option value="50">50人以下</option>',
  '            <option value="51">51～300人</option>',
  '            <option value="301">301～1000人</option>',
  '            <option value="1001">1001人以上</option>',
  '          </select>',
  '          <span class="mauticform-errormsg" style="display: none"></span>',
  '        </div>',
  '        <div',
  '          id="mauticform_zengzhangmofangguanwang_submit"',
  '          class="mauticform-row mauticform-button-wrapper mauticform-field-5"',
  '        >',
  '          <button',
  '            type="submit"',
  '            name="mauticform[submit]"',
  '            id="mauticform_input_zengzhangmofangguanwang_submit"',
  '            value=""',
  '            class="mauticform-button"',
  '          >',
  '            预约营销顾问',
  '          </button>',
  '        </div>',
  '      </div>',
  '    </div>',
  '    <input',
  '      type="hidden"',
  '      name="mauticform[formId]"',
  '      id="mauticform_zengzhangmofangguanwang_id"',
  '      value="1"',
  '    />',
  '    <input',
  '      type="hidden"',
  '      name="mauticform[return]"',
  '      id="mauticform_zengzhangmofangguanwang_return"',
  '      value=""',
  '    />',
  '    <input',
  '      type="hidden"',
  '      name="mauticform[formName]"',
  '      id="mauticform_zengzhangmofangguanwang_name"',
  '      value="zengzhangmofangguanwang"',
  '    />',
  '  </form>',
  '</div>',
].join('');

export default FormHtmlContent;
