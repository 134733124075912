import React from 'react';
import { Button } from 'antd';
import './TravelMarket.scss';
import TravelImg from '@/assets/images/旅程市场@1x-1.png';
import BusinessProtectImg from '@/assets/images/business_protect.png';
import TravelSureImg from '@/assets/images/travel_sure.png';
import ConsultImg from '@/assets/images/consult.png';

function TravelMarket(props) {
  const { openModal } = props;
  const handleJumpToForm = (anchorName) => {
    // if (anchorName) {
    //   let anchorElement = document.getElementById(anchorName);
    //   if (anchorElement) {
    //     anchorElement.scrollIntoView();
    //   }
    // }
    openModal();
  };
  const TravelMarketArrs = [
    {
      icon: BusinessProtectImg,
      title: '业务护航',
      content:
        '行业营销专家为你的业务定制自动化，个性化，场景交互化，创新型的营销解决方案',
    },
    {
      icon: TravelSureImg,
      title: '旅程保障',
      content:
        '将每项活动与客户旅程联系起来。销售和营销策略彼此联系，帮助进行客户分析、促进成单',
    },
    {
      icon: ConsultImg,
      title: '营销咨询',
      content:
        '提供各行业私域流量运营的最佳实践，包括客户营销、活动策划、电商引流等全行业解决方案',
    },
  ];

  return (
    <div className="travelLayout">
      <div className="travelText">
        <span className="travelTitle">
          旅程市场，营销专家为您的业务保驾护航
        </span>
        <span className="travelDetail">
          平台常驻行业营销专家，为您的业务设计个性化营销解决方案
        </span>
      </div>
      <div className="travelContent">
        <div className="travelImg">
          <img alt="" src={TravelImg} className="userImg" />
        </div>
        <div className="travelTab">
          {TravelMarketArrs.map((item) => (
            <div className="tabTitle">
              <div className="travelImgBackground">
                <img src={item.icon} alt="" className="tabLogo" />
              </div>
              <div>
                <span className="tabMainTitle">{item.title}</span>
                <span className="tabContent">{item.content}</span>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="travelButton">
        <Button
          type="primary"
          className="travelBtn"
          onClick={() => handleJumpToForm('targetForm')}
        >
          立即体验
        </Button>
      </div>
    </div>
  );
}

export default TravelMarket;
