import React from 'react';
import { Card, Button } from 'antd';
import './PrivateFlow.scss';
import PrivateImg from '@/assets/images/Group256_@2x.png';
import onlineLogo from '@/assets/images/online.png';
import boxLogo from '@/assets/images/box.png';
import circleLogo from '@/assets/images/circle.png';
import dataLogo from '@/assets/images/data.png';
const { Meta } = Card;

function PrivateFlow(props) {
  const { openModal } = props;
  const handleJumpToForm = (anchorName) => {
    // if (anchorName) {
    //   let anchorElement = document.getElementById(anchorName);
    //   if (anchorElement) {
    //     anchorElement.scrollIntoView();
    //   }
    // }
    openModal();
  };

  const ImgContentArrs = [
    {
      img: onlineLogo,
      title: '线上化',
      content: '线下线上客户统一平台线上化管理，大幅减少客户管理成本',
    },
    {
      img: boxLogo,
      title: '营销化',
      content:
        '全渠道与客户沟通手段，海量互联网营销工具，玩转互联网营销，一个平台就够',
    },
    {
      img: circleLogo,
      title: '自动化',
      content:
        '根据客户所处生命周期，自动化执行营销策略，精心设计客户路程，打造个性化品牌营销',
    },
    {
      img: dataLogo,
      title: '数据化',
      content:
        '客户全生命周期数据化，营销全情况数据化，所有数据统一出口，让您不再为多数据源烦恼',
    },
  ];
  return (
    <div className="privateLayout">
      <div className="privateText">
        <span className="privateTitle">私域流量，让您的业务进入超级增长</span>
        <span className="privateDetail">
          我们以企业微信为基建，让您简单高效实现业务超级增长
        </span>
      </div>
      <div className="privateCard">
        {ImgContentArrs.map((item, index) => {
          return (
            <div
              className="imgBlock"
              style={{ marginRight: index === 3 ? 0 : 55 }}
            >
              <div className="img">
                <span
                  style={{
                    display: 'inline-block',
                    width: 92,
                    height: 92,
                    background: '#f8f8fa',
                    textAlign: 'center',
                    verticalAlign: 'center',
                    borderRadius: '50%',
                  }}
                >
                  <img alt="example" src={item.img} className="imgCard" />
                </span>
              </div>
              <div className="imgTitle">{item.title}</div>
              <div className="imgContent">{item.content}</div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default PrivateFlow;
