import React, { useState } from 'react';
import logo from './logo.svg';
import './App.css';

import ImmediaExperience from '@/ImmediaExperience';
import PrivateFlow from '@/PrivateFlow';
import UserTravel from '@/UserTravel';
import TravelMarket from '@/TravelMarket';
import FissionMarket from '@/FissionMarket';
import MarketPlatform from '@/MarketPlatform';
import UserExample from '@/UserExample';
import RegisterFrom from '@/components/RegisterForm';
function App() {
  const [visible, setVisible] = useState(false);
  const closeModal = () => {
    setVisible(false);
  };
  const openModal = () => {
    setVisible(true);
  };
  return (
    <div className="App">
      <ImmediaExperience
        visible={visible}
        closeModal={() => closeModal()}
        openModal={() => openModal()}
      />
      <PrivateFlow
        visible={visible}
        closeModal={() => closeModal()}
        openModal={() => openModal()}
      />
      <UserTravel
        visible={visible}
        closeModal={() => closeModal()}
        openModal={() => openModal()}
      />
      <TravelMarket
        visible={visible}
        closeModal={() => closeModal()}
        openModal={() => openModal()}
      />
      <FissionMarket
        visible={visible}
        closeModal={() => closeModal()}
        openModal={() => openModal()}
      />
      <MarketPlatform
        visible={visible}
        closeModal={() => closeModal()}
        openModal={() => openModal()}
      />
      <UserExample
        visible={visible}
        closeModal={() => closeModal()}
        openModal={() => openModal()}
      />
      <RegisterFrom
        visible={visible}
        closeModal={() => closeModal()}
        openModal={() => openModal()}
      />
    </div>
  );
}

export default App;
